<template>
  <div class="choose-question">
    <el-dialog :title="`选择${type===4?'宣教':'问卷'}内容`"  :visible.sync="visible" >
<!--      <div class="title">
        <div class="name">{{type===4?'宣教':'问卷'}}类别</div>
        <div class="name">{{type===4?'宣教':'问卷'}}名称</div>
      </div> -->
      <el-container >

      <el-aside style="border: 1px solid #EEEEEE;margin-right: 5%;" width="50%">
		<div class="qxzyfs">请选择要发送的内容</div>
		<el-input placeholder="请输入关键字查询" v-model="searchValue" style="width: 250px;height: 30px;margin-left: 20px;margin-bottom: 10px;" class="input-with-select">
		  <el-button slot="append" @click="gettreeDate()" style="height: 30px;" icon="el-icon-search"></el-button>
		</el-input>
			<OrgTree v-if="visible" ref="tree" @refreshDataList="getList" :content="content" :type='type' v-bind="$attrs"></OrgTree>
		</el-aside>
    <el-aside width="45%" class='right' style=''>
    	<div class="qxzyfs">已选择的内容</div>
	   <el-table
	     :data="itemList"
	     style="width: 100%"
	   >
	     <el-table-column prop="name" label="内容"></el-table-column>

	    <el-table-column width="100" label="操作" fixed="right" align="right" header-align="right">
	       <template slot-scope="scope">
	   
	       
			 <img @click="delContent(scope.row,scope.$index)" style="width: 14px;height: 14px;" src="../../../assets/img/fadel.png" alt="">
	       </template>
	     </el-table-column>
	   </el-table>
   <!--     <el-checkbox-group v-model="items" @change='clickCheck'>
          <el-checkbox  v-for='item in itemList' :key='item.id'  :label='item' :disabled='item.disabled' :checked='item.checked'
          style='height:28px;line-height:28px;display:block;margin-left:20px;margin-top:10px'>{{item.name}}</el-checkbox>

        </el-checkbox-group> -->
		</el-aside>
      </el-container>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
	import OrgTree from './tree/tree2'
export default {
  name:'chooseQuestion',
  props:['type'],
  components: {
			OrgTree,
		},
  data(){
    return {
      radio:'',
	  content:'',
      items:[],
	  searchValue:'',
      visible:false,
      options:[],
      props:{
        // emitPath:false,
        lazy:true,
        value:'value',
        label:'name',
        lazyLoad:async (node,resolve)=>{
          console.log(node.value,type);
          const {level} = node;
          // console.log(level);
          if(level===1){
            if(this.type==5){

              const {data:res} = await this.$http({
                url:this.$http.adornUrl('/indexLibrary/list'),
                method:'get',
                params:{
                  contentType:node.value
                }
              })
              let list = []
              if(res.data.length){
                this.itemList = res.data
                 list = Array.from(res.data).map(v=>(
                  {
                  value:v.id,
                  name:v.libraryName,
                  leaf:level>=1
                  }
                ))
              }else{
                list = [{value:123,name:'暂无数据',leaf:level>=1,disabled:true}]
              }
              // console.log(list,'list');
              // console.log(res,'load res ');
              resolve(list)
            }else{
              const {data:res} = await this.$httpAes({
                url:this.$httpAes.adornUrl('/missionaryWork/list'),
                method:'post',
                params:{
                  stringParam2:node.value
                }
              })
              let list = []
              if(res.data.length){
                this.itemList = res.data
                 list = Array.from(res.data).map(v=>(
                  {
                  value:v.id,
                  name:v.workName,
                  leaf:level>=1
                  }
                ))
              }else{
                list = [{value:123,name:'暂无数据',leaf:level>=1,disabled:true}]
              }
              // console.log(list,'list');
              // console.log(res,'load res ');
              resolve(list)
            }
          }
        }
      },
      item:'',
      itemList:[],//懒加载的item列表
      initType:''
    }
  },
  created () {
    
    this.initType = this.type
    console.log(this.type,this.initType);
  },
  mounted() {
  },
  methods: {
    clickCheck(v){
      console.log(v,'v');
    },
	delContent(item,index){
		this.itemList.splice(index,1)
		this.$refs.tree.setChecked(item)
	},
    change(){
      console.log('change',this.item);
      this.item = []
    },
    getParentNode(n,arr){
      // console.log('getParentNode','n',n,arr,'arr');
      if(n.parent&&n.parent.data.name){
        // console.log(n.parent.data.name,'n.parent.data.name',arr,'arr');
        arr.unshift(n.parent.data.value)
        this.getParentNode(n.parent,arr)
      }else{
        return arr
      }
    },
    async getList(data,checked, node){
        console.log(data,checked, node);
	  let arr = []
	 if(data.children==undefined){
	 	arr.push(data)
	 }
	
	  if(arr.length){
	    this.itemList = arr
	  }else{
	    this.itemList = [{value:123,name:'暂无数据',disabled:true}]
	  }

    },
   gettreeDate(){
	   this.content = this.searchValue
   },
    async init(){
      this.visible = true
      console.log(this.type,'init type');
      let type = this.type
      let dict = ''
      // if(type==2){
      //   type=4
      // }
      // if(type==1){
      //   type=5
      // }
      if(type===5){
        dict ='表单分类'
      }else{
        dict ='宣教分类'
      }
      // await this.getType(dict)
    },
    edit(items){
      this.items = items
    },
    async confirm(){

   let _this = this;
   let params = {
     questionNameId: _this.itemList[0].id,
     questionName:_this.itemList[0].name,
   };

   _this.$emit("setQuestionId", params);
   _this.visible = false;
    }
  },
  // watch:{
  //   'this.type':function(value){
  //     console.log(value,'value watch');
  //     this.initType = value
  //   }
  // }
}
</script>
<style lang="scss" >
.choose-question{
	.el-dialog__footer{
		padding-top: 10px;
    padding-bottom: 20px;
	}
	.qxzyfs{
		height: 20px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 20px;
		margin: 19px;
	}
	.el-dialog{
		width: 960px !important;
		// height: 640px !important;
		margin-top: 10vh !important;
	}
	.el-dialog__body{
		padding-top: 0;
		padding-bottom: 0;
	}
  /deep/.el-cascader-menu{
    width: 50%
  }
  .title{
    width: 100%;
    display:flex;
    text-align: center;
    height: 40px;
    line-height: 40px;
    border: 1px solid #cccccc;
    border-bottom: none;
    .name{
      width: 50%;
    }
  }
  .itemList{
    display: flex;
    flex-direction:column;
    padding:10px 10px;

    
  }
  .right{
	  height: 530px;
    // border:1px solid #ccc;border-left:none;
	border: 1px solid #EEEEEE;

  }
}
</style>